import { lowerFirst } from 'lodash';

export default (components) => {
  const componentsData = {};

  components.forEach((componentData) => {
    const objectKey = lowerFirst(componentData.__typename.replace('ContentfulComponent', ''));
    if (objectKey === 'localCache') {
      componentsData[`${lowerFirst(componentData.contentTypeName.replace('ContentfulComponent', ''))}ContentKey`] = componentData.contentKey;
    } else if (componentsData[objectKey]) {
      if (Array.isArray(componentsData[objectKey])) {
        componentsData[objectKey].push(componentData);
      } else {
        componentsData[objectKey] = [componentsData[objectKey], componentData];
      }
    } else {
      componentsData[objectKey] = componentData;
    }
  });

  return componentsData;
};
