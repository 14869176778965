import {
  ArticleLibrary,
  SlideyTabs,
  Tab,
  TabList,
  TabPanel,
  TitleMedium
} from '@animoto/components';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import {
  articleLibraryWrap,
  marginContainer,
  title as titleClass
} from './CategorizedArticleLibrary.module.css';

export default function CategorizedArticleLibraryModule({
  className,
  data
}) {
  const {
    articleLibraryCategoryGroups,
    hasMarginBottom,
    title
  } = data;

  const categories = articleLibraryCategoryGroups.map(({ categoryTitle }) => categoryTitle);

  const allArticles = [];
  articleLibraryCategoryGroups.forEach((article) => allArticles.push(article.articlePreviewItemList));
  const allPushedArticles = allArticles.concat.apply([], allArticles);

  const classes = classNames(className, {
    [marginContainer] : hasMarginBottom
  });

  return (
    <div className={classes}>
      <TitleMedium className={titleClass}>
        {title}
      </TitleMedium>
      <SlideyTabs
        backgroundColor="white"
        isTabsAlignLeft
      >
        <TabList>
          {categories.map((category) => <Tab key={category}>{category}</Tab>)}
          <Tab key="all">All</Tab>
        </TabList>
        {articleLibraryCategoryGroups.map((articleLibrary) => (
          <TabPanel key={articleLibrary.id}>
            <ArticleLibrary
              articlePreviewItemList={articleLibrary.articlePreviewItemList}
              className={articleLibraryWrap}
              initialPageSize={12}
              primaryCTACopy="See more case studies"
            />
          </TabPanel>
        ))}
        {articleLibraryCategoryGroups.map(() => (
          <TabPanel key="all">
            <ArticleLibrary
              articlePreviewItemList={allPushedArticles}
              className={articleLibraryWrap}
              initialPageSize={12}
              primaryCTACopy="See more case studies"
            />
          </TabPanel>
        ))}
      </SlideyTabs>
    </div>
  );
}

export const CategorizedArticleLibraryDataPropsObject = {
  articleLibraryCategoryGroups : PropTypes.arrayOf(
    PropTypes.shape({
      articlePreviewItemList : PropTypes.arrayOf(
        PropTypes.shape({
          articlePreviewBody          : PropTypes.string,
          articlePreviewCategory      : PropTypes.string,
          articlePreviewCategoryUrl   : PropTypes.string,
          articlePreviewImageSrcFluid : PropTypes.shape({
            aspectRatio : PropTypes.number,
            sizes       : PropTypes.string,
            src         : PropTypes.string,
            srcSet      : PropTypes.string,
            srcSetWebp  : PropTypes.string,
            srcWebp     : PropTypes.string
          }),
          articlePreviewPostUrl : PropTypes.string,
          articlePreviewTitle   : PropTypes.string
        })
      ),
      categoryTitle   : PropTypes.string,
      id              : PropTypes.string,
      initialPageSize : PropTypes.num
    })
  ),
  hasMarginBottom : PropTypes.bool,
  title           : PropTypes.string
};

CategorizedArticleLibraryModule.propTypes = {
  className : PropTypes.string,
  data      : PropTypes.shape(CategorizedArticleLibraryDataPropsObject).isRequired
};

CategorizedArticleLibraryModule.defaultProps = {
  className : null
};

export const CategorizedArticleLibraryModuleData = graphql`
  fragment CategorizedArticleLibraryModuleData on ContentfulComponentCategorizedArticleLibrary {
    articleLibraryCategoryGroups {
      id
      categoryTitle
      articlePreviewItemList {
        articlePreviewBody
        articlePreviewCategory
        articlePreviewCategoryUrl
        articlePreviewImageSrcFluid {
          fluid(maxWidth: 270) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        articlePreviewPostUrl
        articlePreviewTitle
      }
    }
    hasMarginBottom
    title
  }
`;
