import { BoldCopy, InlineLink, Paragraph } from '@animoto/components';
import PropTypes from 'prop-types';
import { createElement } from 'react';
import RehypeReact from 'rehype-react';

const renderAst = new RehypeReact({
  createElement,
  components : {
    p : Paragraph,
    a : InlineLink,
    b : BoldCopy
  }
}).Compiler;

export default function ModuleMarkdown({ children }) {
  return renderAst(children);
}

ModuleMarkdown.propTypes = {
  children : PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types
};
